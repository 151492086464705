import { EventParameter, Inventory } from '@/plugins/sismojs/src/types'
import { GenericAction, SismoazurEventParameter } from '@/types'
import { parse } from '@/plugins/sismojs/src/core/station/text'

export const loadEvents: GenericAction = ({ commit, getters }, data) => {
  return new Promise((resolve, reject) => {
    commit('SET_LOADING', true)
    const t1 = new Date().getTime()
    getters.fdsnClient.getEvents(data).then((events: EventParameter[]) => {
      const t2 = new Date().getTime()
      // console.log(`time to download events: ${t2 - t1} ms`)
      commit('SET_LOADING', false)
      // const VALID_EVENT_TYPES = ['earthquake', 'explosion', 'quarry blast', 'induced or triggered event', 'other event']
      // const filteredEvents = events.filter(x => x.type === '' || VALID_EVENT_TYPES.indexOf(x.type) >= 0)
      // commit('SET_EVENTS', filteredEvents)
      console.log(events)
      commit('SET_EVENTS', events)
      resolve(null)
    })
  })
}

export const loadStations: GenericAction = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    commit('SET_LOADING', true)
    const xhr = new XMLHttpRequest()
    xhr.open('GET', 'configured-station')
    xhr.responseType = 'json'
    xhr.onload = () => {
      commit('SET_LOADING', false)
      commit('SET_STATIONS', xhr.response)
      resolve(null)
    }
    xhr.send()
  })
}

export const focusStation: GenericAction = ({ commit, getters }, data) => {
  if (data == null || getters.focusStation === data) {
    commit('FOCUS_STATION', null)
    return
  }
  commit('FOCUS_STATION', data)
}

export const updateEvent: GenericAction = ({ commit, getters }, data: SismoazurEventParameter) => {
  commit('SET_LOADING', true)
  getters.fdsnClient.getEvents({
    format: 'xml',
    eventid: data.public_id,
    includearrivals: 'true'
  }).then((events: EventParameter[]) => {
    if (events.length > 0 && events[0]._po != null) {
      commit('SET_LOADING', false)
      commit('FOCUS_EVENT', events[0])
    } else {
      commit('SET_LOADING', false)
      throw new Error('No event retrieved')
    }
  })
}

export const focusEvent: GenericAction = ({ commit, getters }, data: SismoazurEventParameter | null) => {
  if (data == null || getters.focusEvent === data) {
    commit('FOCUS_EVENT', null)
    return
  }
  commit('FOCUS_EVENT', data)
  // const cachedEvent = getters.eventById(data)
  if (getters.fmMode) {
    return
  }

  if (data._po.arrival != null) {
    return
  }
  commit('SET_LOADING', true)
  getters.fdsnClient.getEvents({
    format: 'xml',
    eventid: data.public_id,
    includearrivals: 'true'
    // includefocalmechanism: true
  }).then((events: EventParameter[]) => {
    if (events.length > 0 && events[0]._po != null) {
      // const eventTime = events[0]._po.time.value.slice(0, 19)
      // const bulk: string[] = []
      // if (events[0]._po.arrival != null) {
      //   for (const a of events[0]._po.arrival) {
      //     if (a._pick != null && a._pick._fdsnid) {
      //       const [n, s, l, c] = a._pick._fdsnid.split('.')
      //       if (getters.station(n, s) == null) {
      //         bulk.push([n, s, l, c, eventTime, eventTime])
      //       }
      //     }
      //   }
      // }
      commit('SET_LOADING', false)
      commit('FOCUS_EVENT', events[0])
      // if (bulk.length === 0) {
      //   commit('SET_LOADING', false)
      //   commit('FOCUS_EVENT', events[0])
      // } else {
      //   getters.fdsnClient.getStationsBulk(bulk).then((data: Inventory) => {
      //     commit('SET_LOADING', false)
      //     commit('SET_INVENTORY', data)
      //     commit('FOCUS_EVENT', events[0])
      //   })
      // }
    } else {
      commit('SET_LOADING', false)
      throw new Error('No event retrieved')
    }
  })
}

export const loadInventory: GenericAction = ({ commit, getters }) => {
  // fetch('inventory', { method: 'GET' }).then(response => {
  if (Object.keys(getters.inventory).length === 0) {
    const now = new Date().toISOString().split('T')[0]
    fetch(`fdsnws/station/1/query?format=text&level=channel&starttime=${now}`, { method: 'GET' }).then(response => {
      if (response.status === 200) {
        response.text().then(txt => {
          const inv = parse(txt)
          commit('SET_INVENTORY', inv)
        })
      }
    })
  }
}

export const focalMechanismParameters: GenericAction = ({ commit }, data) => {
  commit('SET_FOCAL_MECHANISM_PARAMETERS', data)
}

export const fmParams: GenericAction = ({ commit }, data) => {
  commit('SET_FM_PARAMETERS', data)
}

export const clearEvents: GenericAction = ({ commit }) => {
  commit('CLEAR_EVENTS')
}

export const setPosition: GenericAction = ({ commit }, data) => {
  commit('SET_POSITION', data)
}
