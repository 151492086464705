
import Vue from 'vue'
import { StringIndexedObject } from './types'

const CHECK_VERSION_TIMEOUT = 600e3

interface StringIndexedAltDocument extends StringIndexedObject, Document {
  msHidden: boolean;
  webkitHidden: boolean;
}

export default Vue.extend({
  data () {
    return {
      drawer: ['xs', 'sm'].indexOf(this.$vuetify.breakpoint.name) < 0,
      mini: false,
      tabValue: 0,
      theme: 0,
      needVersionCheck: false,
      geolocationDialog: false,
      locale: 0,
      localeList: ['fr', 'en'],
      authDialog: false,
      username: '',
      password: '',
      showPassword: false,
      authAlertType: 'success',
      authAlertText: ''
    }
  },
  mounted () {
    // const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)')
    // if (darkThemeMq) {
    //   this.theme = 1
    // }
    this.initLocale()
    if (navigator.geolocation) {
      if (!this.$store.state.positionAsked) {
        this.geolocationDialog = true
      } else {
        if (this.$store.state.positionAccepted) {
          this.requestPosition()
        }
      }
    }
    if (navigator.serviceWorker != null) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        window.location.reload()
      })
    }
    this.checkVersion()
    this.checkAuthentication()
    this.handleVisibility()
    const theme = localStorage.getItem('theme')
    this.theme = theme != null ? parseInt(theme) : 0
  },
  watch: {
    theme: function (value) {
      this.$vuetify.theme.dark = value === 1
      localStorage.setItem('theme', value)
    },
    '$store.state.documentHidden': function (value) {
      if (this.needVersionCheck === true) {
        this.checkVersion()
      }
    }
  },
  methods: {
    initLocale () {
      let locale = localStorage.getItem('locale')
      if (locale == null) {
        const browserLocale = navigator.language.slice(0, 2)
        if (this.$i18n.availableLocales.indexOf(browserLocale) >= 0) {
          locale = browserLocale
        } else {
          locale = this.$i18n.fallbackLocale as string
        }
      }
      if (locale != null) {
        this.setLocale(locale)
      }
    },
    checkAuthentication () {
      fetch('/check_auth').then(response => {
        if (response.status === 200) {
          response.json().then(value => {
            if (value === 'connected') {
              this.$store.state.connected = true
            }
          })
        }
      })
    },
    handleAuthentication () {
      const credentials = btoa(`${this.username}:${this.password}`)
      fetch('/login', {
        method: 'GET',
        headers: {
          Authorization: `Basic ${credentials}`
        }
      }).then(response => {
        if (response.status === 200) {
          response.json().then(value => {
            if (value === 'success') {
              this.$store.state.connected = true
              this.authAlertType = 'success'
              this.authAlertText = value
              setTimeout(() => {
                this.authDialog = false
                this.authAlertText = ''
                this.showPassword = false
              }, 1000)
            }
          })
        } else {
          this.authAlertType = 'error'
          this.authAlertText = response.statusText
        }
      })
    },
    handleLogout () {
      fetch('/logout', {
        method: 'GET'
      }).then(response => {
        this.$store.state.connected = false
      })
    },
    setLocale (locale: string) {
      this.locale = this.localeList.indexOf(locale)
      localStorage.setItem('locale', locale)
      this.$i18n.locale = locale
    },
    handleGeolocationResponse (response: boolean) {
      this.geolocationDialog = false
      this.$store.commit('SET_POSITION_ASKED')
      this.$store.commit('SET_POSITION_ACCEPTED', response)
      if (response) {
        this.requestPosition()
      }
    },
    requestPosition () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(pos => {
          this.$store.dispatch('setPosition', pos)
        })
      }
    },
    checkVersion () {
      if (this.$store.state.documentHidden === true) {
        this.needVersionCheck = true
        return
      }
      this.$store.commit('CHECK_VERSION')
      setTimeout(() => {
        this.checkVersion()
      }, CHECK_VERSION_TIMEOUT)
    },
    handleVisibility () {
      const doc = document as StringIndexedAltDocument
      let hidden: string,
        visibilityChange: string
      if (typeof doc.msHidden !== 'undefined') {
        hidden = 'msHidden'
        visibilityChange = 'msvisibilitychange'
      } else if (typeof doc.webkitHidden !== 'undefined') {
        hidden = 'webkitHidden'
        visibilityChange = 'webkitvisibilitychange'
      } else {
        hidden = 'hidden'
        visibilityChange = 'visibilitychange'
      }
      doc.addEventListener(visibilityChange, () => {
        this.$store.commit('SET_DOCUMENT_HIDDEN', doc[hidden])
      })
    }
  }
})
